$(document).ready(function (){
    setTimeout(function () {
      $('body').addClass('container-loaded');
    }, 100);

    //-------- Index main slider -----///
    $('.main-slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false
    });

    //button for scroll down to contacts form
    $(".contacts-redirect").on('click', function (){
        $('html, body').animate({
            scrollTop: $("#contacts").offset().top - 55
        }, 800);
    });

    $('.arrow-box').on('click', function(){
        var nextSection = $('.section').next();

        $('html,body').animate({
            scrollTop: nextSection.offset().top-60
        }, 'slow');
    });

    $('.mobile-navigation .navbar-toggle').click(function(){
        $(this).toggleClass('open');
    });

    $(function() {
      $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top - 55
            }, 1000);
            return false;
          }
        }
      });
    });

    $(function() {
        var typingTimer;
        var typingInterval = 5000;

        $(".counter input").on("change paste keyup", function() {
            var currentValue = $(this).val();
            var oldValue = $(this).data('old-value');
            var price = $("input[name='price']").val();
            var licenses = $("input[name='licenses']").val();

            $(this).data('old-value', currentValue);

            if ($(this).attr('name') == 'price') {
                if (!currentValue || currentValue.length === 0) {
                    $('.counter .euro').hide();
                } else {
                    $('.counter .euro').show();
                }
            }

            clearTimeout(typingTimer);
            if (price && licenses && (currentValue != oldValue)) {
                typingTimer = setTimeout((roiCount(price, licenses)), typingInterval);
            }
        });
    });

    function roiCount(price, licenses) {
        var url = $("#counterRoute").val();

        $.ajax({
            type: "GET",
            url: url,
            async: false,
            data: {
                'price': price,
                'licenses': licenses
            },
            dataType    : 'json', // what type of data do we expect back from the server
            encode      : true,
            success : function(response)
            {
                var data = response.data;
                var success = response.success;

                if (success) {
                    $('.price-month').html(data.employee_price);
                    $('.time-saving').html(data.monthly_hours_saved);
                    $('.money-savings').html(data.monthly_savings);
                    $('.total').html(data.roi);
                }
            }
        });
    }

    $(function() {
        var form = $('#pricing-form');
        var button = $('.pricing-request button');

        button.click(function() {
            var parent = $(this).parents('.pricing-container');
            var title = parent.find('.head p').text();

            form.find('h4').text(title);
            form.find("input[name='plan']").val(title);
        });
    });
});
